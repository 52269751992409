import React from 'react';
import ReactMapboxGl, { Marker, Layer } from "react-mapbox-gl";

const Mapbox = ReactMapboxGl({
  accessToken: "pk.eyJ1IjoidGV1c3RpY2UyIiwiYSI6ImNrOHJvMzIxbzAyZ24za3A1d2FzOGhyZnIifQ.5Q-t23k5TuGESim4V6wX0Q"
});


class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: -95.7574877,
      lat: 36.2408711,
      zoom: 3.5,
    };
  }

  render() {
    const markers = this.props.stateData.map((state, i) => {
      if (state.coords.lng) {
        return (
          <Marker
            key={`map-marker-${i}`}
            coordinates={[state.coords.lng, state.coords.lat]}
            style={{
              backgroundColor: 'black',
              color: 'white',
              lineHeight: 'normal',
              padding: '3px',
              fontSize: 11,
              fontWeight: 600
            }}
            anchor="bottom">
            <div>{state[this.props.selectedMenu]}</div>
          </Marker>
        )
      }
    })

    return (
      <Mapbox
        className="map"
        style={'mapbox://styles/teustice2/ck8ruk4mj01lh1iqj2l5wvflr'}
        center={[this.state.lng, this.state.lat]}
        zoom={[this.state.zoom]}
      >
        {markers}
      </Mapbox>
    );
  }
}

export default Map;
