import React from 'react';
import './App.css';

import { stateCoords } from './lib/stateCoords';
import Map from './components/Map';
import Menu from './components/Menu';
import { logDOM } from '@testing-library/react';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      stateData: undefined,
      selectedMenu: 'positive'
    }
    this.handleMenuClick = this.handleMenuClick.bind(this)
  }

  componentDidMount() {
    fetch('https://api.covidtracking.com/api/states').then((res) => {
      return res.json()
    }).then((json) => {
      this.addCoordsToData(json)
      this.setState({ stateData: json })
    })
  }

  addCoordsToData(json) {
    return json.map((state) => {
      let newState = state;
      let coordArray = stateCoords.filter(coords => coords[0] === state.state);

      let coords = {
        lat: coordArray[0] ? coordArray[0][1] : undefined,
        lng: coordArray[0] ? coordArray[0][2] : undefined,
      }

      newState.coords = coords;
      newState.population = coordArray[0] ? coordArray[0][3] : undefined;

      if(newState.population) {
        newState.percentPositive = ((newState.positive / newState.population) * 100).toFixed(2) + '%';
      }

      return state = newState;
    })
  }

  handleMenuClick(slug) {
    this.setState({ selectedMenu: slug })
  }

  render() {
    return (
      <div className="application-wrapper">
        {this.state.stateData &&
          <div>
            <Menu handleMenuClick={this.handleMenuClick} selectedMenu={this.state.selectedMenu} />
            <Map stateData={this.state.stateData} selectedMenu={this.state.selectedMenu} />
          </div>
        }
      </div>
    );
  }
}

export default App;
