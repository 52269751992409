// [state, lat, lng, population]
export const stateCoords = [
  [ 'AK', 63.588753, -154.493062, 710231 ],
  [ 'AL', 32.318231, -86.902298, 4779736 ],
  [ 'AR', 35.20105, -91.831833, 2915918 ],
  [ 'AZ', 34.048928, -111.093731, 6392017 ],
  [ 'CA', 36.778261, -119.417932, 37253956 ],
  [ 'CO', 39.550051, -105.782067, 5029196 ],
  [ 'CT', 41.603221, -73.087749, 3574097 ],
  [ 'DC', 38.905985, -77.033418, 601723 ],
  [ 'DE', 38.910832, -75.52767, 897934 ],
  [ 'FL', 27.664827, -81.515754, 18801310 ],
  [ 'GA', 32.157435, -82.907123, 9687653 ],
  [ 'HI', 19.898682, -155.665857, 1360301 ],
  [ 'IA', 41.878003, -93.097702, 3046355 ],
  [ 'ID', 44.068202, -114.742041, 1567582 ],
  [ 'IL', 40.633125, -89.398528, 12830632 ],
  [ 'IN', 40.551217, -85.602364, 6483802 ],
  [ 'KS', 39.011902, -98.484246, 2853118 ],
  [ 'KY', 37.839333, -84.270018, 4339367 ],
  [ 'LA', 31.244823, -92.145024, 4533372 ],
  [ 'MA', 42.407211, -71.382437, 6547629 ],
  [ 'MD', 39.045755, -76.641271, 5773552 ],
  [ 'ME', 45.253783, -69.445469, 1328361 ],
  [ 'MI', 44.314844, -85.602364, 9883640 ],
  [ 'MN', 46.729553, -94.6859, 5303925 ],
  [ 'MO', 37.964253, -91.831833, 5988144 ],
  [ 'MS', 32.354668, -89.398528, 2967297 ],
  [ 'MT', 46.879682, -110.362566, 989415 ],
  [ 'NC', 35.759573, -79.0193, 9535483 ],
  [ 'ND', 47.551493, -101.002012, 672591 ],
  [ 'NE', 41.492537, -99.901813, 1826341 ],
  [ 'NH', 43.193852, -71.572395, 1316470 ],
  [ 'NJ', 40.058324, -74.405661, 8791894 ],
  [ 'NM', 34.97273, -105.032363, 2059179 ],
  [ 'NV', 38.80261, -116.419389, 2700551 ],
  [ 'NY', 43.299428, -74.217933, 19378102 ],
  [ 'OH', 40.417287, -82.907123, 11536504 ],
  [ 'OK', 35.007752, -97.092877, 3751351 ],
  [ 'OR', 43.804133, -120.554201, 3831074 ],
  [ 'PA', 41.203322, -77.194525, 12702379 ],
  [ 'RI', 41.580095, -71.477429, 1052567 ],
  [ 'SC', 33.836081, -81.163725, 4625364 ],
  [ 'SD', 43.969515, -99.901813, 814180 ],
  [ 'TN', 35.517491, -86.580447, 6346165 ],
  [ 'TX', 31.968599, -99.901813, 25145561 ],
  [ 'UT', 39.32098, -111.093731, 2763885 ],
  [ 'VA', 37.431573, -78.656894, 8001024 ],
  [ 'VT', 44.558803, -72.577841, 625741 ],
  [ 'WA', 47.751074, -120.740139, 6724540 ],
  [ 'WI', 43.78444, -88.787868, 5686986 ],
  [ 'WV', 38.597626, -80.454903, 1852994 ],
  [ 'WY', 43.075968, -107.290284, 563626 ]
]