import React from 'react';

const menuItems = [
  {
    title: 'Tested Positive',
    slug: 'positive'
  },
  {
    title: 'Tested Negative',
    slug: 'negative'
  },
  {
    title: 'Test Pending',
    slug: 'pending'
  },
  {
    title: 'Percent Positive',
    slug: 'percentPositive'
  },
  {
    title: 'Death',
    slug: 'death'
  },
  {
    title: 'Recovered',
    slug: 'recovered'
  },
  {
    title: 'Hospitalized Currently',
    slug: 'hospitalizedCurrently'
  }
]

class Menu extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const selectedMenu = this.props.selectedMenu;

    const menu = menuItems.map((item, i) => {
      return (
        <button key={`menu-button-${i}`} className={`menu__button ${item.slug === selectedMenu ? 'active' : ''}`} onClick={() => this.props.handleMenuClick(item.slug)}>
          {item.title}
        </button>
      )
    })
    return (
      <div className="menu">
        {menu}
      </div>
    );
  }
}

export default Menu;
